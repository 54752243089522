import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"

import CartContext from "../../context/cartContext"
import { useHorizontalScroll } from "../../services/hooks"
import WebpImage from "../WebpImage"

import "./style.scss"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import useNewRxFlow from "../../utils/useNewRxFlow";
import {accessoryInfo} from "../../services/accessoryDictionary";

const RecommendsList = ({ recommendsList }) => {
  const scrollRef = useHorizontalScroll()

  return (
    <div ref={scrollRef} className="recommends-list">
      {recommendsList
        .filter(e => accessoryInfo.find(_e => _e.title.includes(e.title) && !_e.soldOut))
        .map((item, index) => (
        <RecommendItem {...item} key={index} />
      ))}
    </div>
  )
}

const RecommendItem = ({ id, image, title, price, type, variantId, product_id }) => {
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const { addItemCart } = useContext(CartContext)

  const addRecomendedItemToCart = () => {
    const newAccessory = {
      id,
      variant_id: variantId,
      sku: `ACCESSORY${id}`,
      image: image,
      product_id,
      title,
      price: price,
      type,
      no_ga_fire: true
    }

    if (typeof window !== 'undefined') {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "quick_add",
        ecommerce: {
          items: [
            {
              item_id: id,
              item_name: title,
              currency: 'USD',
              discount: 0,
              index: 0,
              item_brand: 'Hubble',
              item_category: "accessories",
              item_category2: "contact accessories",
              item_category3: 'N/A',
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id:   `N/A`,
              item_list_name: `N/A`,
              item_variant:   `N/A`,
              price: parseFloat(price),
              index: 1
            }
          ]
        }
      })
    }

    return addItemCart(newAccessory)
  }

  return (
    <div className={`recommend-item ${isNewRxDesktop || isNewRx ? "new-rx" : ""}`} onClick={addRecomendedItemToCart}>
      <div
        className="image-block"
      >
        <WebpImage fileName={image} />
      </div>
      <div className="text-block">
        <p className={`title text h10 black ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>{title}</p>
        <p className="price text h10 black">${price.toFixed(2)}</p>
        <a className="quick-add">
          <div className="round-container">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <span className="text h10 deep-blue">Quick Add</span>
        </a>
      </div>
    </div>
  )
}

export default RecommendsList
