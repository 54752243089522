export const recommendsList = [
  {
    id: 123,
    type: "accessory",
    title: "Biotrue Hydration Boost Eye Drops",
    price: 12.99,
    variantId: "42508253266120",
    product_id: "7497157148872",
    image: "Cart/Biotrue.png",
  },
  {
    id: 124,
    type: "accessory",
    title: "Ocuvite Blue Light",
    variantId: "42508265619656",
    product_id: "7497162129608",
    price: 15.99,
    image: "Cart/Ocuvite.png",
  },
]

export const skyhyMonthlyRecommendsList = [
  ...recommendsList,
  {
    id: 14642680332656,
    type: "accessory",
    title: "Sight Savers® Contact Lens Case",
    variantId: "51712682033520",
    product_id: "14642680332656",
    price: 1.50,
    image: "Cart/Sight-Savers-Lens-Case.png",
  },
]
